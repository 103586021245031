/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {FC} from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import {Stack, Typography} from "@mui/material";



const Bio: FC<{ showBio?: boolean }> = (props) => {

  var style = {
    mainDiv : {
      marginBottom: 30,
      display: "flex",
      "align-items": "center"
    }
  }

  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 75, height: 75, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.ts
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  const avatar = data?.avatar?.childImageSharp?.fixed

  return (
    <Stack direction="row" alignItems="center">
      {avatar && (
        <Image
          fixed={avatar}
          alt={author?.name || ``}
          className="bio-avatar"
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
      )}
      {author?.name && (
        <Typography display="inline">
          By <strong>{author.name}.</strong>&nbsp;
        </Typography>
      )}
      {props?.showBio && (
        <Typography display="inline">
          If this was helpful or you have any other questions, reach out on <a href={`https://twitter.com/${social?.twitter || ``}`}> Twitter</a>! Happy to help.
        </Typography>
      )}
    </Stack>
  )
}

export default Bio
